import React, { useCallback } from "react";
import Link from "next/link";

import { CATEGORY_NAMES } from "constants/dispensary";
import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import useFilters from "hooks/useFilters";

import IconSvg from "components/IconSvg";
import Placeholder, { Rect } from "components/Placeholder";

type Props = {
  className?: string;
  coins: string[];
  filterable?: boolean;
  hrefLink?: string;
  hrefLinkQueryParam?: string;
  hrefProductCatQueryKey?: string;
  isLoading?: boolean;
  title?: string;
};

const CoinList: React.FC<Props> = ({
  className,
  coins,
  filterable,
  hrefLink = "/search/shop",
  hrefLinkQueryParam = "",
  hrefProductCatQueryKey = "product_category",
  isLoading,
  title,
}) => {
  const { publishEvent } = useEventTracker();

  const { toggleFilter } = useFilters({
    shallowRouting: true,
  });

  const onFilterClick = useCallback(
    (filterOption: string) => {
      toggleFilter({ [`product_category[]`]: filterOption });
    },
    [toggleFilter],
  );

  const coinCategories = Object.keys(CATEGORY_NAMES).filter((category) =>
    coins.includes(category),
  );

  if (!isLoading && !coinCategories.length) {
    return <></>;
  }

  return (
    <div className={className}>
      {title && <h2 className="heading--m font-extrabold mb-md">{title}</h2>}
      <div className="overflow-x-auto">
        <ul data-testid="coin-list" className="flex gap-lg">
          {isLoading
            ? Array.from({ length: 6 }).map((_, i) => (
                <li
                  className="flex-shrink-0 mb-2 md:mb-none"
                  key={`category-coin-placeholder-${i}`}
                >
                  <Placeholder
                    className="w-20 h-20"
                    data-testid="category-coin-placeholder"
                  >
                    <Rect height="100%" width="100%" />
                  </Placeholder>
                </li>
              ))
            : coinCategories.map((category) => (
                <Coin
                  key={category}
                  href={`${hrefLink}?${hrefProductCatQueryKey}=${category}${hrefLinkQueryParam}`}
                  onClick={() => {
                    publishEvent({
                      action: Action.click,
                      category: Category.productCategoryCoinFilter,
                      label: CATEGORY_NAMES[category]
                        .toLowerCase()
                        .replace(/-/g, ""),
                    });
                    filterable && onFilterClick(category);
                  }}
                  title={CATEGORY_NAMES[category].toLowerCase()}
                  filterable={filterable}
                  category={category}
                />
              ))}
        </ul>
      </div>
    </div>
  );
};

const Coin = ({
  category,
  filterable,
  onClick,
  href,
  title,
}: {
  category: string;
  onClick: () => void;
  href: string;
  filterable?: boolean;
  title: string;
}) => {
  return (
    <li key={category} className="flex-shrink-0 mb-2 md:mb-none min-w-20 w-20">
      <Link
        href={filterable ? "" : href}
        onClick={onClick}
        className="flex border border-grey-44 flex-col items-center justify-center h-[80px] bg-green-20 rounded-[8px]"
        data-testid={`${category}-products-category`}
      >
        <IconSvg
          className={`flex items-center text-white ${
            category === "Flower" && "pt-1"
          }`}
          width={category === "Flower" ? "44" : "50"}
          height={category === "Flower" ? "42" : "50"}
          filePath={`coinList/${category.toLowerCase()}.svg`}
        />
        <span className="text-xs">{title}</span>
      </Link>
    </li>
  );
};

export default CoinList;
