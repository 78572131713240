import isEmpty from "lodash/isEmpty";
import range from "lodash/range";

import consumerApi from "api/services/consumer-api";
import { DISPENSARY_FILTER_NAMES } from "constants/dispensary";
import { MenuItem } from "custom-types/MenuItem";
import logError from "utils/logError";
import { MENU_TITLES } from "utils/menuTypeUtils";
import { normalizeLegacyParams } from "utils/normalizeLegacyParams";

import { mapMenuItemImages } from "./getMenuItem";

export const DEFAULT_TAKE_COUNT = 18;

// Takes the URL params and translates them for consumer-api
const formatParamsForGetMenuItems = (
  {
    sort,
    thc_pips,
    cbd_pips,
    price,
    pickup_available,
    menu_type,
    boost_strain,
    ...otherParams
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  any,
  allowedFilters = DISPENSARY_FILTER_NAMES,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const formattedParams = {} as any;

  // Special handling for sort formatting
  if (sort) {
    const [type, dir] = sort.split(":");
    if (type && dir) {
      formattedParams[`sort[0]${type}`] = dir;
    }
  }

  // Special handling for the thc and cbd pips ranges
  // These must be sent as an array of values e.g. [2,3,4]
  if (thc_pips) {
    const { min = 0, max = 5 } = thc_pips;
    formattedParams["filter[thc_pips]"] = range(
      parseInt(min, 10),
      parseInt(max, 10) + 1,
    );
  }

  if (cbd_pips) {
    const { min = 0, max = 5 } = cbd_pips;
    formattedParams["filter[cbd_pips]"] = range(
      parseInt(min, 10),
      parseInt(max, 10) + 1,
    );
  }

  if (price) {
    const { min, max } = price;
    formattedParams["filter[price_min]"] = min;
    formattedParams["filter[price_max]"] = max;
  }

  if (pickup_available) {
    formattedParams["filter[pickup_available]"] = true;
  }

  if (menu_type) {
    formattedParams["filter[medical]"] =
      menu_type === MENU_TITLES.med.shortTitle ||
      menu_type === MENU_TITLES.med.shortLower;
  }

  if (boost_strain) {
    formattedParams["boost"] = "strain_name";
    formattedParams["strain_name"] = boost_strain;
  }

  // Validate that this is a recognized filter
  // Format the naming of all other params
  Object.entries(otherParams).forEach(([key, value]) => {
    if (allowedFilters[key]) {
      formattedParams[`filter[${key}]`] = value;
    }
  });

  return formattedParams;
};

export type GetMenuItemsAvailableFilters = {
  count?: number;
  filterIcon?: string | null;
  label: string;
  labelIcon?: string | null;
  name: string;
  showCount?: boolean;
  sortOrder?: number;
  sublabel?: string | null;
  type?: string;
  values?: {
    badge?: string | null;
    chip?: string | null;
    count: number;
    label?: string;
    sortOrder?: number;
    type?: string;
    value: string;
  }[];
}[];

// Any optional params here is availble when passing
// enableNewFilters=true. All required params are
// available in either case.
export type GetMenuItemsResponse = {
  data: MenuItem[];
  metadata: {
    availableFilters: GetMenuItemsAvailableFilters;
    availableSorts: string[];
    relevanceOrder: Record<string, number | null>;
    supportedFilters: string[];
    totalCount: number;
  };
};

export type GetMenuItemsReturn = {
  availableFilters: GetMenuItemsAvailableFilters;
  availableSorts: string[];
  menuItems: MenuItem[];
  supportedFilters: string[];
  totalItems: number;
};

const getMenuItems = async (
  dispensarySlug: string,
  query: Record<string, string | string[] | number | boolean | undefined>,
  supportedFilters: { [key: string]: boolean } = DISPENSARY_FILTER_NAMES,
) => {
  // normalizeLegacyParams is legacy code for handling old filter names, work towards not needing it
  // This is most likely not needed any longer
  const normalizedParams = normalizeLegacyParams(query);
  const { page = 1, perPage, q, ...otherParams } = normalizedParams;
  const take =
    perPage !== undefined && perPage < DEFAULT_TAKE_COUNT // make sure per page is reasonable
      ? perPage
      : DEFAULT_TAKE_COUNT;

  // Calculate skip based on page and take
  const skip = parseInt(page, 10) > 1 ? (parseInt(page, 10) - 1) * take : 0;

  const formattedParams = {
    enableNewFilters: query?.enableNewFilters,
    skip,
    take: take,
    ...(q && { q }),
    ...formatParamsForGetMenuItems(
      otherParams,
      isEmpty(supportedFilters) ? DISPENSARY_FILTER_NAMES : supportedFilters,
    ),
  };

  try {
    const {
      data: {
        data,
        metadata: {
          availableFilters,
          availableSorts,
          totalCount,
          supportedFilters,
        },
      },
    } = await consumerApi.get<GetMenuItemsResponse>(
      `/api/dispensaries/v1/${dispensarySlug}/menu_items`,
      {
        params: formattedParams,
      },
    );

    return {
      availableFilters: availableFilters || [],
      availableSorts: availableSorts || [],
      menuItems: (data || []).map(mapMenuItemImages) as MenuItem[],
      supportedFilters: supportedFilters || [],
      totalItems: totalCount || 0,
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getMenuItems",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return {
      availableFilters: [],
      availableSorts: [],
      menuItems: [],
      supportedFilters: [],
      totalItems: 0,
    };
  }
};

export default getMenuItems;
