import React, { useContext } from "react";
import { useSelector } from "react-redux";

import MenuContext from "context/MenuContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { getCurrentDispensary } from "redux/selectors/dispensary";
import { dispensaryHref } from "utils/dispensaryUrls";

import CoinList from "components/CoinList";

const CategoryCarousel: React.FC<{
  className?: string;
  filterable?: boolean;
}> = ({ className, filterable }) => {
  const countryCode = useDomainCountryCode();

  const { slug: dispensarySlug, retailType } =
    useSelector(getCurrentDispensary);

  const {
    selectors: { categories, loading },
  } = useContext(MenuContext);

  const hrefLink = dispensaryHref({
    additionalPath: "/menu",
    countryCode,
    retailType,
    slug: dispensarySlug,
  });

  return (
    <CoinList
      className={className}
      coins={categories}
      filterable={filterable}
      hrefLink={hrefLink}
      hrefProductCatQueryKey="product_category[]"
      isLoading={loading}
      title="Shop by category"
    />
  );
};

export default CategoryCarousel;
